/*
 * horizontal forms generated by <ArrayInput className="horizontal-form">
 */
.horizontal-form {
  width: 100% !important;
}
.horizontal-form ul {
  /*width: 100%;*/
}

.horizontal-form ul  li {
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.react-select-container > div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin-top: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.react-select-container-error > div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid rgba(244, 67, 54, 1) !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin-top: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.react-select-container > div:hover {
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

.react-select-container-error > div:hover {
  border-bottom: 2px solid rgba(244, 67, 54, 1);
}

.gridWithSearchableFilter > div {
  overflow: visible;
}

.hidden {
  display: none
}

.visible {
  display: block
}

.required-field-error {
  color: #f44336;
  font-size: 0.75rem;
  margin: 0;
  text-align: left;
  min-height: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1em;
}